export const useLanguageStore = defineStore('languageStore', () => {
  const runtimeConfig = useRuntimeConfig();
  const envData = runtimeConfig?.public;
  const {
    locale,
    locales,
    setLocaleCookie,
  } = useI18n();
  const defaultLangObj = locales?.value?.find((lang) => lang.code === locale?.value);

  const language = ref(defaultLangObj);

  const showLanguagePicker = ref(false);

  const getCurrentLanguage = computed(() => {
    return language?.value;
  });

  const isLanguagePickerOpen = computed(() => {
    return showLanguagePicker?.value;
  });

  function setLanguageByCode(language) {
    const selectedLang = language || locale;
    const langObj = locales?.value?.find((lang) => lang.code === selectedLang);
    setLanguage(langObj || locale);
  }

  function setLanguage(langObj) {
    language.value = langObj;

    // @TODO: Check secure status and how to set the value of the cookie in a different manner
    const languageCookie = useCookie('language', {
      // default: () => ({ value: newCurrencyObject?.code }),
      // default: () => newCurrencyObject?.code,
      domain: envData?.COOKIE_DOMAIN,
      path: '/',
      secure: true,
      sameSite: 'Strict',
    });

    setLocaleCookie(language?.value?.code);
    languageCookie.value = language?.value?.code;
  }

  function toggleLanguagePicker(isVisible) {
    showLanguagePicker.value = isVisible;
  }

  return {
    language,
    showLanguagePicker,
    getCurrentLanguage,
    isLanguagePickerOpen,
    setLanguage,
    setLanguageByCode,
    toggleLanguagePicker,
  };
});
